<template>
  <div class="p-4">
    <div
      class="inline-flex items-center mb-3 text-lightGrey-2 cursor-pointer"
      @click.stop="$emit('back')"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="14"
        viewBox="0 0 8 14"
        fill="none"
      >
        <path
          d="M7.08004 0.993291C6.75337 0.666624 6.22671 0.666624 5.90004 0.993291L0.360039 6.53329C0.100039 6.79329 0.100039 7.21329 0.360039 7.47329L5.90004 13.0133C6.22671 13.34 6.75337 13.34 7.08004 13.0133C7.40671 12.6866 7.40671 12.16 7.08004 11.8333L2.25337 6.99996L7.0867 2.16662C7.4067 1.84662 7.40671 1.31329 7.08004 0.993291Z"
          fill="#6B6873"
        />
      </svg>
      <p>
        <span class="ml-3 text-sm">Back</span>
      </p>
    </div>

    <h5
      class="md:text-2xl text-xl text-center font-bold primary-text leading-10"
    >
      Upload bank statement
    </h5>
    <p class="text-center text-grey md:text-lg md:px-5 mt-2">
      To give you access to credit, upload at least
      <b>6 months account</b> statement from your most active bank account.
    </p>
    <Input
      placeholder="Enter account number"
      v-model="accountData.accountNumber"
      type="number"
      class="mt-6"
      width="w-full"
    />

    <Select
      class="mt-4"
      width="w-full"
      label="prettyName"
      placeholder="Select Bank"
      :options="pdfBanks"
      :reduce="(bank) => bank.id"
      v-model="accountData.bank"
      errorText="Please select a bank"
      :validation="rules.bank"
    />

    <Upload
      :text="text"
      class="mt-4"
      :fileType="['pdf']"
      @upload="handleUpload"
      @delete="pdfFile = ''"
    />
    <Input
      placeholder="Enter PDF password if applicable"
      v-model="accountData.pdfPassword"
      type="password"
      class="mt-4"
      width="w-full"
      autoComplete="new-password"
    />

    <div class="success-credit mt-8">
      <svg
        class="mr-2"
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="41"
        viewBox="0 0 20 21"
        fill="none"
      >
        <path
          d="M9 5.5H11V7.5H9V5.5ZM9 9.5H11V15.5H9V9.5ZM10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5Z"
          fill="#0EA2F5"
        />
      </svg>
      <div>
        <p class="text-xs md:text-sm">
          You can not upload bank statements more than 5 times in a month.
        </p>
      </div>
    </div>

    <Button
      width="w-full"
      class="mt-4"
      text="Submit"
      :disabled="validButton"
      @click="verifyPdf"
      :loading="loading"
    />
    <Modal :display="displayValidationStatus" hideClose size="large">
      <VerificationStatus
        @back="
          displayValidationStatus = false;
          $emit('back');
        "
        type="pdf"
        @close="$emit('close')"
        :queryResult="queryResult"
        @connectBank="displayValidationStatus = false"
        @success="handleSuccess"
      />
    </Modal>
  </div>
</template>
<script>
  import { mapActions, mapState } from "vuex";
  import { Input, Select, Upload } from "@/UI/Input";
  import { Button } from "@/UI/Button";
  import { DATA_STATUS } from "@/utils/sharedData/home";
  import { Modal } from "@/UI/Modals";
  import {
    PDF_ANALYSIS,
    QUERY_PDF_ANALYSIS,
    PDF_SUPPORTED_BANKS,
  } from "@/utils/api/verification";
  export default {
    components: {
      Input,
      Select,
      Button,
      Upload,
      Modal,
      AnimateHeight: () => import("@/UI/AnimateHeight"),
      VerificationStatus: () => import("./VerificationStatus.vue"),
    },
    data: () => ({
      pdfFile: "",
      text: "",
      loading: false,
      accountData: {
        accountNumber: "",
        bank: "",
        pdfStatementId: "",
        pdfPassword: "",
      },
      dataStatus: DATA_STATUS,
      queryTiming: {
        timeout: null,
        timeLeft: 120,
      },
      queryCounter: null,
      queryResult: {},
      bankCheckStatus: {
        available: false,
      },
      queryCount: 0,
      pdfBanks: [],
      displayValidationStatus: false,
    }),
    computed: {
      ...mapState({
        eligibleAmount: (state) =>
          state?.userInfo?.creditQualification?.eligibilityCriteriaResult
            .eligibleAmount,
      }),
      rules() {
        return {
          accountNumber: this?.accountData?.accountNumber?.length === 10,
          bank: this.accountData?.bank?.length > 0,
          pdf: this.accountData?.pdfStatementId?.length > 0,
        };
      },
      validButton() {
        return Object.values(this.rules).includes(false);
      },
    },
    mounted() {
      this.getPdfBanks();
    },
    methods: {
      ...mapActions("userInfo", ["getCreditQualification"]),
      ...mapActions("notification", ["showAlert"]),
      ...mapActions("dashboard", ["showCreditQualificationStep"]),
      ...mapActions("loading", ["setLoading"]),
      handleUpload(e) {
        this.accountData.pdfStatementId = e.id;
      },
      verifyPdf() {
        this.queryTiming.timeLeft = 120;
        this.startVerification();
        this.displayValidationStatus = false;
      },
      startVerification() {
        this.setLoading({
          display: true,
          title: "We are analysing your document",
          description:
            "This will only take some minutes. Please do not leave this sceeen.",
        });
        PDF_ANALYSIS(this.accountData)
          .then(({ data }) => {
            this.queryPdfAnalysis(data.data.id);
          })
          .catch((error) => {
            if (error?.response?.status === 404) {
              this.showAlert({
                display: true,
                type: "cancel",
                description: error.response.data.message,
                title: "An error occurred",
              });
            }
            this.setLoading(false);
          });
      },
      queryPdfAnalysis(pdfAnalysisId) {
        if (this.queryTiming.timeLeft === 120) {
          this.startQueryTimeout();
        }
        // if the 2mins timeout has passed
        if (this.queryTiming.timeLeft <= 0) {
          this.setLoading(false);
          this.queryResult.dataStatus = this.dataStatus.pending;
          this.displayValidationStatus = true;
        } else {
          QUERY_PDF_ANALYSIS(pdfAnalysisId)
            .then(({ data }) => {
              if (data?.data?.dataStatus !== this.dataStatus?.pending) {
                // get credit qualification status to get eligible amount
                this.getCreditQualification().then(() => {
                  this.queryResult = data?.data;
                  this.queryResult.eligibleAmount = this.eligibleAmount;
                  this.setLoading(false);
                  this.displayValidationStatus = true;
                });
                // if status remains pending
              } else {
                // recurse the function
                this.queryCounter = setTimeout(() => {
                  this.queryPdfAnalysis(pdfAnalysisId);
                }, 2000);
              }
            })
            .catch(() => {
              this.setLoading(false);
              if (this.queryTiming.timeLeft > 0) {
                clearInterval(this.queryTiming.timeout);
                this.queryTiming.timeLeft = 120;
              }
            });
        }
      },
      getPdfBanks() {
        PDF_SUPPORTED_BANKS().then(({ data }) => {
          this.pdfBanks = data.data;
        });
      },
      startQueryTimeout() {
        this.queryTiming.timeout = setInterval(() => {
          if (this.queryTiming.timeLeft <= 0) {
            clearInterval(this.queryTiming.timeout);
          }
          this.queryTiming.timeLeft -= 1;
        }, 1000);
      },
      // handleClose() {
      //   this.$emit('close')
      //   this.showCreditQualificationStep(false);
      // },
      handleSuccess() {
        this.$emit("success");
      },
    },
    destroyed() {
      clearTimeout(this.queryCounter);
      clearInterval(this.queryTiming.timeout);
    },
  };
</script>
<style scoped>
  .error-message {
    border: 1px solid #ffd5cc;
    color: #fc3c11;
    background: #fff7f5;
    padding: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
  }

  .success-credit {
    background: rgba(243, 243, 246, 0.5);
    padding: 12px;
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
    width: 100%;
    color: #6b6873;
  }
</style>
