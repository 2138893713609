var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "p-4" },
    [
      _c(
        "div",
        {
          staticClass:
            "inline-flex items-center mb-3 text-lightGrey-2 cursor-pointer",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.$emit("back")
            },
          },
        },
        [
          _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                width: "8",
                height: "14",
                viewBox: "0 0 8 14",
                fill: "none",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M7.08004 0.993291C6.75337 0.666624 6.22671 0.666624 5.90004 0.993291L0.360039 6.53329C0.100039 6.79329 0.100039 7.21329 0.360039 7.47329L5.90004 13.0133C6.22671 13.34 6.75337 13.34 7.08004 13.0133C7.40671 12.6866 7.40671 12.16 7.08004 11.8333L2.25337 6.99996L7.0867 2.16662C7.4067 1.84662 7.40671 1.31329 7.08004 0.993291Z",
                  fill: "#6B6873",
                },
              }),
            ]
          ),
          _vm._m(0),
        ]
      ),
      _c(
        "h5",
        {
          staticClass:
            "md:text-2xl text-xl text-center font-bold primary-text leading-10",
        },
        [_vm._v(" Upload bank statement ")]
      ),
      _vm._m(1),
      _c("Input", {
        staticClass: "mt-6",
        attrs: {
          placeholder: "Enter account number",
          type: "number",
          width: "w-full",
        },
        model: {
          value: _vm.accountData.accountNumber,
          callback: function ($$v) {
            _vm.$set(_vm.accountData, "accountNumber", $$v)
          },
          expression: "accountData.accountNumber",
        },
      }),
      _c("Select", {
        staticClass: "mt-4",
        attrs: {
          width: "w-full",
          label: "prettyName",
          placeholder: "Select Bank",
          options: _vm.pdfBanks,
          reduce: function (bank) {
            return bank.id
          },
          errorText: "Please select a bank",
          validation: _vm.rules.bank,
        },
        model: {
          value: _vm.accountData.bank,
          callback: function ($$v) {
            _vm.$set(_vm.accountData, "bank", $$v)
          },
          expression: "accountData.bank",
        },
      }),
      _c("Upload", {
        staticClass: "mt-4",
        attrs: { text: _vm.text, fileType: ["pdf"] },
        on: {
          upload: _vm.handleUpload,
          delete: function ($event) {
            _vm.pdfFile = ""
          },
        },
      }),
      _c("Input", {
        staticClass: "mt-4",
        attrs: {
          placeholder: "Enter PDF password if applicable",
          type: "password",
          width: "w-full",
          autoComplete: "new-password",
        },
        model: {
          value: _vm.accountData.pdfPassword,
          callback: function ($$v) {
            _vm.$set(_vm.accountData, "pdfPassword", $$v)
          },
          expression: "accountData.pdfPassword",
        },
      }),
      _c("div", { staticClass: "success-credit mt-8" }, [
        _c(
          "svg",
          {
            staticClass: "mr-2",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: "40",
              height: "41",
              viewBox: "0 0 20 21",
              fill: "none",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M9 5.5H11V7.5H9V5.5ZM9 9.5H11V15.5H9V9.5ZM10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5Z",
                fill: "#0EA2F5",
              },
            }),
          ]
        ),
        _vm._m(2),
      ]),
      _c("Button", {
        staticClass: "mt-4",
        attrs: {
          width: "w-full",
          text: "Submit",
          disabled: _vm.validButton,
          loading: _vm.loading,
        },
        on: { click: _vm.verifyPdf },
      }),
      _c(
        "Modal",
        {
          attrs: {
            display: _vm.displayValidationStatus,
            hideClose: "",
            size: "large",
          },
        },
        [
          _c("VerificationStatus", {
            attrs: { type: "pdf", queryResult: _vm.queryResult },
            on: {
              back: function ($event) {
                _vm.displayValidationStatus = false
                _vm.$emit("back")
              },
              close: function ($event) {
                return _vm.$emit("close")
              },
              connectBank: function ($event) {
                _vm.displayValidationStatus = false
              },
              success: _vm.handleSuccess,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { staticClass: "ml-3 text-sm" }, [_vm._v("Back")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticClass: "text-center text-grey md:text-lg md:px-5 mt-2" },
      [
        _vm._v(" To give you access to credit, upload at least "),
        _c("b", [_vm._v("6 months account")]),
        _vm._v(" statement from your most active bank account. "),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", { staticClass: "text-xs md:text-sm" }, [
        _vm._v(
          " You can not upload bank statements more than 5 times in a month. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }